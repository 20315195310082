import React from 'react'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { COLOR_CONSTANTS, colors, radius, fontWeights } from 'theme'
import { CONTAINER_MAX_WIDTH } from 'consts'
import { Flex, Box } from 'components/Layout'
import Container from 'components/Container'
import { Text } from 'components/Typography'
import LocalImage from 'components/LocalImage'
import Button from 'components/Button'

import bgImageSocialMedias from 'static/images/integrations/bg_social_medias.webp'

import { pxToRem } from '../../helpers'

const BgImgBackground = styled(LocalImage)`
  top: 0;
  left: 0;
  height: 100%;
  pointer-events: none;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
  left: ${({ left }) => left || '50%'};
  top: ${({ top }) => top || 0};
  transform: translate(-50%);
  height: ${({ height }) => height || 'auto'};
  object-fit: ${({ objectFit }) => objectFit || 'cover'};
`

const StyledWrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  background: radial-gradient(50% 81.39% at 50% 50%, #0e6ce7 0%, #005ed7 46.67%, #0054c0 100%);
  overflow: hidden;
`

const StyledHeaderTextWrapper = styled(Flex)`
  border-radius: ${radius.m};
  background-color: ${transparentize(0.8, colors.white)};
`

const StyledButtonReversed = styled(Button.Reversed)`
  box-shadow: 0px 18px 24px -8px rgb(36 130 253 / 32%);
  font-weight: ${fontWeights.bold};
  height: 100%;
  padding: 0 48px;
`

const BorderedBox = styled(Box)`
  border-radius: ${radius.pill};
  z-index: 0;
  position: absolute;
  border: 1px solid ${({ amount }) => transparentize(1 - amount, colors.white)};
  background-color: transparent;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const GetStartedSection = () => {
  return (
    <StyledWrapper>
      <BorderedBox width="789px" height="791px" amount={0.1} />
      <BorderedBox width="931px" height="933px" amount={0.2} />
      <BorderedBox width="1107px" height="1109px" amount={0.27} />
      <BorderedBox width="1309px" height="1310px" amount={0.35} />
      <BorderedBox width="1543px" height="1546px" amount={0.42} />
      <BorderedBox width="1839px" height="1842px" amount={0.35} />
      <Container
        py={{ mobile: 'l', tablet: 'xxl' }}
        my={{ mobile: 'l', tablet: 'xxl' }}
        pl="m"
        pr="m"
        position="relative"
        maxWidth={CONTAINER_MAX_WIDTH}
        width="100%"
        height="100%"
      >
        <BgImgBackground
          src={bgImageSocialMedias}
          width="auto"
          objectFit={{ mobile: 'contain', tablet: 'cover' }}
          height="100%"
          display={{ mobile: 'none', tablet: 'block' }}
        />
        <Flex flexDirection="column" width="100%" zIndex="2">
          <Flex alignItems="center" flexDirection="column" position="relative">
            <Flex>
              <StyledHeaderTextWrapper fontWeight="bold" fontSize="3xl" color="white">
                Build and grow
              </StyledHeaderTextWrapper>
            </Flex>
            <Text fontWeight="bold" fontSize="3xl" color="white">
              stronger relationships on social
            </Text>
          </Flex>
          <Flex mt="m" pt="s" alignItems="center" flexDirection="column" maxWidth="540px" mx="auto">
            <Text color={COLOR_CONSTANTS.SALT} fontSize="l">
              Vista Social helps you understand and reach your audience, engage your community and measure performance
              with the only all-in-one social media management with easy-to-use features like publishing, engagement,
              reviews, reports and listening.
            </Text>
          </Flex>
          <Flex
            mt="xl"
            alignItems="center"
            flexDirection="column"
            height={{ mobile: pxToRem(56), tablet: pxToRem(52) }}
          >
            <StyledButtonReversed as="a" href="/pricing">
              Get started now
            </StyledButtonReversed>
          </Flex>
        </Flex>
      </Container>
    </StyledWrapper>
  )
}

export default GetStartedSection
