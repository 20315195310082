import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { COLOR_CONSTANTS, colors, radius } from 'theme'
import { CONTAINER_MAX_WIDTH } from 'consts'
import { Flex, Box, Grid } from 'components/Layout'
import Container from 'components/Container'
import { Text, H3 } from 'components/Typography'
import LocalImage from 'components/LocalImage'
import { changeTextColorByMatch } from '../../helper'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`

const ToolManageSection = ({ network, headerText, description, image, tools }) => {
  return (
    <Wrapper bg={COLOR_CONSTANTS.SALT}>
      <Container
        my={{ mobile: 'l', tablet: 'xxl' }}
        pl={{ mobile: 'l', tablet: 'l' }}
        pr={{ mobile: 'l', tablet: 'l' }}
        position="relative"
        maxWidth={CONTAINER_MAX_WIDTH}
        width="100%"
        height="100%"
      >
        <Flex flexDirection="column" alignItems="center">
          <Flex
            justifyContent={{ mobile: 'center', desktop: 'space-between' }}
            flexDirection={{ mobile: 'column', desktop: 'row' }}
            width="100%"
          >
            <Flex
              alignItems="center"
              mx={{ mobile: 'auto', tablet: 0 }}
              flexDirection="column"
              maxWidth={{ mobile: '100%', desktop: '445px' }}
              width="100%"
            >
              <Flex width="100%" justifyContent="center" flexDirection="column">
                <Flex flexDirection={{ mobile: 'row', desktop: 'column' }} flexWrap="wrap" justifyContent="center">
                  <H3
                    fontSize="3xl"
                    fontWeight="bold"
                    color={COLOR_CONSTANTS.DENIM}
                    textAlign={{ mobile: 'center', desktop: 'left' }}
                    dangerouslySetInnerHTML={{
                      __html: changeTextColorByMatch({
                        text: headerText,
                        mainColor: COLOR_CONSTANTS.DENIM,
                        changeColor: colors.primary,
                        matches: [network],
                      }),
                    }}
                  />
                </Flex>
                <Text
                  mt="m"
                  pt="s"
                  color="secondaryText"
                  fontSize="l"
                  textAlign={{ mobile: 'center', desktop: 'left' }}
                  fontWeight="normal"
                >
                  {description}
                </Text>
              </Flex>
            </Flex>
            <Flex
              maxWidth="624px"
              minWidth={{ mobile: '100%', desktop: '516px', desktopWide: '624px' }}
              ml="auto"
              mr={{ mobile: 'auto', desktop: 0 }}
              mt={{ mobile: 'l', desktop: 0 }}
            >
              <LocalImage src={image} width="100%" maxWidth="624px" mx="auto" alt={headerText} />
            </Flex>
          </Flex>

          <Grid mt="xl" gridGap="l" gridTemplateColumns={{ mobile: 'repeat(1, 1fr)', tablet: 'repeat(3, 1fr)' }}>
            {tools.map(({ title, description }) => {
              return (
                <Flex key={title} maxWidth="350px" width="100%" flexDirection="column">
                  <Flex alignItems={{ mobile: 'center', tablet: 'unset' }}>
                    <Box
                      mt={{ mobile: 0, tablet: '11px' }}
                      minWidth="8px"
                      width="8px"
                      height="8px"
                      borderRadius={radius.pill}
                      bg={colors.primary}
                    />
                    <Text fontWeight="medium" color={COLOR_CONSTANTS.DENIM} fontSize="xl" ml="s" textAlign="left">
                      {title}
                    </Text>
                  </Flex>
                  <Text mt="m" color="secondaryText" fontSize="m" textAlign="left">
                    {description}
                  </Text>
                </Flex>
              )
            })}
          </Grid>
        </Flex>
      </Container>
    </Wrapper>
  )
}

ToolManageSection.defaultProps = {
  tools: [],
}

ToolManageSection.propTypes = {
  network: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  tools: PropTypes.array,
}

export default ToolManageSection
