import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { G2_FEATURES } from 'consts'
import { Flex } from 'components/Layout'
import { Text } from 'components/Typography'
import LocalImage from 'components/LocalImage'
import { space } from 'theme'

const StyledItemWrapper = styled(Flex)`
  cursor: pointer;
`

const G2FeaturesComponent = ({ imageWidth, imageHeight, textAlign }) => {
  return (
    <Flex flexDirection="column">
      <Text textAlign={textAlign} fontWeight="medium" fontSize="2xl" color="secondaryText">
        {G2_FEATURES.title}
      </Text>
      <Flex
        flexWrap="wrap"
        alignItems="center"
        justifyContent={{ mobile: 'center', desktop: 'space-between' }}
        my="m"
        width="100%"
      >
        {G2_FEATURES.badges.map(({ alt, image }) => (
          <Flex key={alt} mx={{ mobile: 'm', desktop: 0 }} my={{ mobile: 's', tablet: 0 }}>
            <StyledItemWrapper as="a" href="https://www.g2.com/products/vista-social/reviews" target="_blank">
              <LocalImage src={image} alt={alt} width={imageWidth} height={imageHeight} />
            </StyledItemWrapper>
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
}

G2FeaturesComponent.defaultProps = {
  imageWidth: '110px',
  imageHeight: 'auto',
  textAlign: 'center',
}

G2FeaturesComponent.propTypes = {
  imageWidth: PropTypes.string,
  imageHeight: PropTypes.string,
  textAlign: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default G2FeaturesComponent
