export const changeTextColorByMatch = ({ text, matches = [], mainColor = '', changeColor = '' }) => {
  if (matches.length !== 0 && text) {
    const updatedMatches = []
    matches.forEach((item) => {
      item.split(' ').forEach((word) => {
        updatedMatches.push(word)
      })
    })
    return text
      .split(' ')
      .map((word) => {
        if (updatedMatches.includes(word)) {
          return `<span style="color: ${changeColor}; font-weight: inherit; font-size: inherit;">${word}</span>`
        }
        return `<span style="color: ${mainColor}; font-weight: inherit; font-size: inherit;">${word}</span>`
      })
      .join(' ')
  }
  return text
}
