import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { COLOR_CONSTANTS } from 'theme'
import { CONTAINER_MAX_WIDTH, FREQUENTLY_ASKED_QUESTIONS } from 'consts'
import { Flex } from 'components/Layout'
import Container from 'components/Container'
import { Text, H3 } from 'components/Typography'
import LocalImage from 'components/LocalImage'

import imageDoubleArrowRightBlue from 'static/images/home/double_arrow_right_blue.svg'

import GridDropdown from '../../../Faq/GridDropdown'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`

const StyledLinkText = styled(Text)`
  display: flex;
  align-items: center;
`

const FaqSection = ({ type = 'all' }) => {
  return (
    <Wrapper>
      <Container
        pl={{ mobile: 'l', tablet: 'l' }}
        pr={{ mobile: 'l', tablet: 'l' }}
        py={{ mobile: 'l', tablet: 'xxl' }}
        position="relative"
        maxWidth={CONTAINER_MAX_WIDTH}
        width="100%"
        height="100%"
      >
        <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
          Frequently Asked{' '}
          <H3 as="span" fontSize="3xl" fontWeight="bold" color="primary">
            Questions
          </H3>
        </H3>
        <Flex flexDirection="column" mt="xl" width="100%">
          <GridDropdown faqs={FREQUENTLY_ASKED_QUESTIONS[type].slice(0, type === 'all' ? 100 : 100)} />
          <Flex mt="l" justifyContent="flex-end">
            <StyledLinkText as="a" href="/faq" textAlign="left">
              <Text fontSize="l" fontWeight="medium" textAlign="left" color="primary" as="span">
                ALL OUR FAQS
              </Text>
              <LocalImage src={imageDoubleArrowRightBlue} mt="-2px" />
            </StyledLinkText>
          </Flex>
        </Flex>
      </Container>
    </Wrapper>
  )
}

FaqSection.defaultProps = {
  type: 'all',
}

FaqSection.propTypes = {
  type: PropTypes.string,
}

export default FaqSection
