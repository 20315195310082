import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { COLOR_CONSTANTS, colors, radius } from 'theme'
import { CONTAINER_MAX_WIDTH } from 'consts'
import { pxToRem } from 'helpers'
import { Flex, Box, Grid } from 'components/Layout'
import Container from 'components/Container'
import { Text, H3 } from 'components/Typography'
import LocalImage from 'components/LocalImage'

import imageDoubleArrowRightBlue from 'static/images/home/double_arrow_right_blue.svg'
import { changeTextColorByMatch } from '../../helper'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`

const StyledLinkText = styled(Text)`
  display: flex;
  align-items: center;
`

const TextImageSection = ({ textImages, background }) => {
  return (
    <Wrapper bg={background}>
      <Container
        pl={{ mobile: 'l', tablet: 'l' }}
        pr={{ mobile: 'l', tablet: 'l' }}
        position="relative"
        maxWidth={CONTAINER_MAX_WIDTH}
        width="100%"
        height="100%"
      >
        {textImages.map(
          (
            {
              header: { title: headerTitle, matches = [] },
              textFirst,
              image,
              items = [],
              link,
              linkText,
              headerMaxWidth = '730px',
            },
            index
          ) => (
            <Flex
              my={{ mobile: 'l', tablet: 'xxl' }}
              flexDirection="column"
              alignItems="center"
              width="100%"
              key={index}
            >
              <Flex zIndex="1" alignItems="center" flexDirection="column" mx="auto" maxWidth={headerMaxWidth}>
                <H3
                  fontSize="3xl"
                  fontWeight="bold"
                  color={COLOR_CONSTANTS.DENIM}
                  textAlign="center"
                  dangerouslySetInnerHTML={{
                    __html: changeTextColorByMatch({
                      text: headerTitle,
                      mainColor: COLOR_CONSTANTS.DENIM,
                      changeColor: colors.primary,
                      matches,
                    }),
                  }}
                />
              </Flex>
              <Flex
                mt="xl"
                justifyContent="center"
                flexDirection={{ mobile: 'column', desktop: 'row' }}
                alignItems="center"
                width="100%"
              >
                <Flex
                  minWidth={{ mobile: '100%', tablet: '445px' }}
                  width="100%"
                  alignItems="center"
                  mx={{ mobile: 'auto', tablet: 0 }}
                  flexDirection="column"
                  order={{ mobile: 1, desktop: textFirst ? 1 : 2 }}
                >
                  <Flex width="100%" justifyContent="center" alignItems="space-between" flexDirection="column">
                    <Grid gridGap={pxToRem(24)} gridTemplateColumns="repeat(1, 1fr)">
                      {items.map(({ title, description }) => {
                        return (
                          <Flex key={title} width="100%" flexDirection="column">
                            <Flex alignItems={{ mobile: 'center', tablet: 'unset' }}>
                              <Box
                                mt={{ mobile: 0, tablet: '11px' }}
                                minWidth="8px"
                                width="8px"
                                height="8px"
                                borderRadius={radius.pill}
                                bg={colors.primary}
                              />
                              <Text
                                fontWeight="medium"
                                color={COLOR_CONSTANTS.DENIM}
                                fontSize="xl"
                                ml="s"
                                textAlign="left"
                              >
                                {title}
                              </Text>
                            </Flex>
                            {description && (
                              <Text mt="m" color="secondaryText" fontSize="m" textAlign="left">
                                {description}
                              </Text>
                            )}
                          </Flex>
                        )
                      })}
                    </Grid>
                    {link && linkText && (
                      <Flex mt="l">
                        <StyledLinkText as="a" href={link} textAlign="left">
                          <Text fontSize="l" fontWeight="medium" textAlign="left" color="primary" as="span">
                            {linkText}
                          </Text>
                          <LocalImage src={imageDoubleArrowRightBlue} mt="-2px" />
                        </StyledLinkText>
                      </Flex>
                    )}
                  </Flex>
                </Flex>
                <Flex
                  maxWidth="740px"
                  minWidth={{ mobile: '100%', desktop: '516px', desktopWide: '740px' }}
                  ml={{ mobile: 'auto', desktop: textFirst ? 'l' : 0 }}
                  mr={{ mobile: 'auto', desktop: textFirst ? 0 : 'l' }}
                  mt={{ mobile: 'l', desktop: 0 }}
                  order={{ mobile: 2, desktop: textFirst ? 2 : 1 }}
                >
                  <LocalImage src={image} width="100%" maxWidth="740px" mx="auto" alt={headerTitle} />
                </Flex>
              </Flex>
            </Flex>
          )
        )}
      </Container>
    </Wrapper>
  )
}

TextImageSection.defaultProps = {
  background: COLOR_CONSTANTS.WHITE,
}

TextImageSection.propTypes = {
  textImages: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.shape({
        title: PropTypes.string.isRequired,
        matches: PropTypes.array,
      }),
      textFirst: false,
      image: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
        })
      ),
      linkText: PropTypes.string,
      link: PropTypes.string,
    })
  ).isRequired,
  background: PropTypes.string,
}

export default TextImageSection
